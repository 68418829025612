import Menu from "@/views/Menu.vue";
export default {
  components: {
    Menu
  },
  computed: {
    isShowMenu: function () {
      return this.$route.path != "/login" && this.$route.path != "/links";
    }
  }
};