import { doPost, doPut, doDelete } from "@/util/request";
import SimpleFormDialog from "@/components/dialog/SimpleFormDialog";
export default {
  components: {
    SimpleFormDialog
  },

  data() {
    return {
      todayList: [],
      fields: [{
        fieldName: "plannedWorkHours",
        fieldLabel: "计划工时(时)",
        isAddField: true
      }, {
        fieldName: "actualWorkHours",
        fieldLabel: "实际工时(时)",
        isAddField: true
      }, {
        fieldName: "plannedStartTime",
        fieldLabel: "计划开始时间",
        isAddField: true,
        type: "datetime"
      }, {
        fieldName: "plannedEndTime",
        fieldLabel: "计划结束时间",
        isAddField: true,
        type: "datetime"
      }, {
        fieldName: "actualStartTime",
        fieldLabel: "实际开始时间",
        isAddField: true,
        type: "datetime"
      }, {
        fieldName: "actualEndTime",
        fieldLabel: "实际结束时间",
        isAddField: true,
        type: "datetime"
      }]
    };
  },

  methods: {
    queryTodayList() {
      doPost("/api/project/projectWbs/todayList", {}).then(response => {
        if (response.code == 200) {
          this.todayList = [];
        } else {
          this.todayList = response;
        }
      });
    },

    onMoreWsbClick() {
      this.$router.push({
        path: "/projectWbs"
      });
    },

    handleComplete(item) {
      this.$refs.formDialog.showModify(item);
    },

    doComplete(item) {
      let param = {
        id: item.id,
        status: 4,
        plannedWorkHours: item.plannedWorkHours,
        actualWorkHours: item.actualWorkHours,
        plannedStartTime: item.plannedStartTime,
        plannedEndTime: item.plannedEndTime,
        actualStartTime: item.actualStartTime,
        actualEndTime: item.actualEndTime
      };
      doPut("/api/project/projectWbs/model", param).then(response => {
        this.$refs.formDialog.close();
        this.queryTodayList();
      });
    }

  },
  mounted: function () {
    this.queryTodayList();
  },
  activated: function () {}
};