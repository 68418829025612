import FormDialog from "@/components/dialog/FormDialog";
import { loadDict } from "@/util/request";
export default {
  emits: ["onAddSubmit", "onModifySubmit"],
  components: {
    FormDialog
  },
  props: {
    fields: Array //字段配置

  },

  data() {
    return {
      formDialogTitle: "",
      formData: {},
      formType: "",
      dictCache: {} //字典缓存

    };
  },

  methods: {
    showAdd(formData) {
      this.formType = "ADD";
      this.formData = formData || {};
      this.$refs.formDialog.show("新增");
    },

    showModify(formData) {
      this.formType = "MODIFY";
      this.formData = formData;
      this.$refs.formDialog.show("修改");
    },

    close() {
      this.$refs.formDialog.close();
    },

    onSubmit() {
      if (this.formType == "ADD") {
        this.$emit("onAddSubmit", this.formData);
      }

      if (this.formType == "MODIFY") {
        this.$emit("onModifySubmit", this.formData);
      }
    },

    cacheDict(field) {
      //缓存字典
      this.dictCache[field.dictName] = {
        status: 0,
        //字典加载中
        dictItems: [] //字典项

      };
      loadDict(field.dictName).then(dictItems => {
        this.dictCache[field.dictName].dictItems = dictItems;
        this.dictCache[field.dictName].status = 1; //已加载
      });
    },

    onChange(parentFuncion, value) {
      if (parentFuncion) {
        parentFuncion(value);
      }
    },

    setFormValue(key, value) {
      this.formData[key] = value;
    }

  },
  mounted: function () {
    this.fields.forEach(field => {
      if (field.dictName) {
        this.cacheDict(field);
      }
    });
  }
};