import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_el_date_picker = _resolveComponent("el-date-picker");

  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_form = _resolveComponent("el-form");

  const _component_form_dialog = _resolveComponent("form-dialog");

  return _openBlock(), _createBlock(_component_form_dialog, {
    onOnSubmit: $options.onSubmit,
    ref: "formDialog"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      inline: true,
      model: $data.formData,
      class: "demo-form-inline"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [item.isAddField ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: item.fieldLabel,
          "label-width": "120px"
        }, {
          default: _withCtx(() => [!item.type || item.type == 'text' ? (_openBlock(), _createBlock(_component_el_input, {
            key: 0,
            modelValue: $data.formData[item.fieldName],
            "onUpdate:modelValue": $event => $data.formData[item.fieldName] = $event,
            readonly: item.readonly,
            style: {
              "width": "220px"
            }
          }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly"])) : _createCommentVNode("", true), item.type == 'textarea' ? (_openBlock(), _createBlock(_component_el_input, {
            key: 1,
            type: "textarea",
            modelValue: $data.formData[item.fieldName],
            "onUpdate:modelValue": $event => $data.formData[item.fieldName] = $event,
            rows: item.rows,
            style: {
              "width": "590px"
            }
          }, null, 8, ["modelValue", "onUpdate:modelValue", "rows"])) : _createCommentVNode("", true), item.type == 'select' ? (_openBlock(), _createBlock(_component_el_select, {
            key: 2,
            modelValue: $data.formData[item.fieldName],
            "onUpdate:modelValue": $event => $data.formData[item.fieldName] = $event,
            placeholder: "请选择",
            disabled: item.disabled,
            style: {
              "width": "220px"
            },
            onChange: value => {
              this.onChange(item.onChange, value);
            }
          }, {
            default: _withCtx(() => [item.showValue ? (_openBlock(true), _createElementBlock(_Fragment, {
              key: 0
            }, _renderList($data.dictCache[item.dictName].dictItems, dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.id,
                label: dict.code + ' | ' + dict.label,
                value: dict.code
              }, null, 8, ["label", "value"]);
            }), 128)) : _createCommentVNode("", true), !item.showValue ? (_openBlock(true), _createElementBlock(_Fragment, {
              key: 1
            }, _renderList($data.dictCache[item.dictName].dictItems, dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.id,
                label: dict.label,
                value: dict.code
              }, null, 8, ["label", "value"]);
            }), 128)) : _createCommentVNode("", true)]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])) : _createCommentVNode("", true), item.type == 'datetime' ? (_openBlock(), _createBlock(_component_el_date_picker, {
            key: 3,
            modelValue: $data.formData[item.fieldName],
            "onUpdate:modelValue": $event => $data.formData[item.fieldName] = $event,
            type: "datetime",
            format: "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            style: {
              "width": "220px"
            }
          }, null, 8, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true), item.type == 'date' ? (_openBlock(), _createBlock(_component_el_date_picker, {
            key: 4,
            modelValue: $data.formData[item.fieldName],
            "onUpdate:modelValue": $event => $data.formData[item.fieldName] = $event,
            type: "date",
            format: "YYYY-MM-DD",
            "value-format": "YYYY-MM-DD",
            style: {
              "width": "220px"
            }
          }, null, 8, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true)]),
          _: 2
        }, 1032, ["label"])) : _createCommentVNode("", true)], 64);
      }), 128))]),
      _: 1
    }, 8, ["model"])]),
    _: 1
  }, 8, ["onOnSubmit"]);
}