export default {
  emits: ["onSubmit"],

  data() {
    return {
      isDialogShow: false,
      title: ""
    };
  },

  methods: {
    show(title) {
      this.isDialogShow = true;

      if (title) {
        this.title = title;
      } else {
        this.title = "表单";
      }
    },

    close() {
      this.isDialogShow = false;
    },

    _onSubmit() {
      this.$emit("onSubmit");
    }

  }
};