import axios from 'axios'
import qs from 'Qs'
import { ElMessageBox, ElMessage } from 'element-plus'
import router from '../router'

//允许接口写cookie
axios.defaults.withCredentials = true
//超时时间
axios.defaults.timeout = 2000;

var isOpenLogin = false//是否打开了登录跳转的box

//  RESPONSE 响应异常拦截
axios.interceptors.response.use(response => {
  //==============  所有请求完成后都要执行的操作  ==================  
  if (response && response.status == 200 && response.data.code != 200) {
    if (response.data.msg) {
      ElMessage(response.data.msg)
    }
  }
  return response.data;
}, err => {
  //==============  未登录错误处理  ====================
  if (err && err.response && err.response.status == 401 && !isOpenLogin && router.currentRoute.value.path != '/login') {
    isOpenLogin = true
    //跳转到登录页面
    ElMessageBox.alert('会话超时，请重新登录', '警告', {
      confirmButtonText: '我知道了',
      callback: (action) => {
        isOpenLogin = false
        router.push({ path: "/login" })
      },
    })
  }
  return err
})

//get请求
export async function doGet(url, headers) {
  var resObj = {
    code: 200,
    msg: "",
    data: {}
  }

  await axios.get(url, { headers: headers })
    .then(data => {
      resObj.data = data;
    })
    .catch(error => {
      resObj.code = 500;
      resObj.msg = error;
    });
  return resObj;
}

//post请求
export async function doPost(url, param) {
  var resData = []
  await axios.post(url, qs.stringify(doRequestParam(param)), { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
    .then(data => {
      if (data.code == 200) {
        if (data.data) {
          resData = data.data
        } else {
          resData = data
        }
      }
    })
    .catch(error => {
      console.error(error)
    });

  return resData
}

//put请求
export async function doPut(url, param) {
  var resData = []
  await axios.put(url, qs.stringify(doRequestParam(param)), { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
    .then(data => {
      if (data.code == 200) {
        if (data.data) {
          resData = data.data
        } else {
          resData = data
        }

      }
    })
    .catch(error => {
      console.error(error)
    });

  return resData
}


//delete请求
export async function doDelete(url) {
  var resData = []
  await axios.delete(url, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
    .then(data => {
      if (data.code == 200) {
        if (data.data) {
          resData = data.data
        } else {
          resData = data
        }

      }
    })
    .catch(error => {
      console.error(error)
    });

  return resData
}

//日期+时间的参数正则 yyyy-MM-dd hh:mm:ss
var dateTimeRegExp = /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/
//日期参数正则yyyy-MM-dd
var dateRegExp = /^(\d{4})\-(\d{2})\-(\d{2})$/

//处理请求参数
function doRequestParam(param) {
  let requestParam = {}
  Object.keys(param).forEach(key => {
    if (param[key]) {
      //处理日期参数
      if (isDate(param[key])) {
        requestParam[key] = "\"" + param[key] + "\""
      } else {
        requestParam[key] = param[key]
      }
    }
  })
  return requestParam
}

//是否是日期参数
function isDate(str) {
  if (dateTimeRegExp.test(str)) {
    return true
  }

  if (dateRegExp.test(str)) {
    return true
  }

  return false
}

//加载字典
export async function loadDict(dictName) {
  if (!dictName) {
    return []
  }
  //从缓存中获取字典
  var dictItems = sessionStorage.getItem(dictName);
  if (dictItems) {
    return JSON.parse(dictItems)
  }

  await doGet('api/dict/sysDict/dict/' + dictName).then(data => {
    dictItems = data.data
    sessionStorage.setItem(dictName, JSON.stringify(dictItems))
  })
  return dictItems
}

//获取字典内容
export function getDictText(dictItems, code) {
  if (!dictItems) {//没有字典，直接返回代码，不翻译
    return null
  }

  if (!code && code != 0) {//没有编码，直接返回代码，不翻译
    return null
  }


  for (let index = 0; index < dictItems.length; index++) {
    if (dictItems[index].code == code) {
      return dictItems[index].label //查到了字典，返回字典内容
    }

    if (dictItems.children) {//有下级字典
      let res = getDictText(dictItems.children, code)
      if (!res) {
        return res //下级查到了字典，返回字典内容
      }
    }
  }

  return code
}


//post下载文件
export function downloadFile(url, params) { //params是post请求需要的参数，url是请求url地址
  var form = document.createElement("form");
  form.style.display = 'none';
  form.action = url;
  form.method = "post";
  document.body.appendChild(form);

  for (var key in params) {
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }

  form.submit();
  form.remove();
}