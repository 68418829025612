import { createApp } from 'vue'
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
//import vue3JsonExcel from "vue3-json-excel";

const app = createApp(App)

//全局配置 size 用于设置表单组件的默认尺寸，zIndex 用于设置弹出组件的层级，zIndex 的默认值为 2000
// app.use(ElementPlus, { zIndex: 2000 })
app.use(router)
//app.use(vue3JsonExcel)
app.mount('#app')


