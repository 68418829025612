import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/links',
    name: 'Links',
    component: () => import('../views/myLink/Links.vue')
  },
  {
    path: '/posPageList',
    name: 'PosPageList',
    component: () => import('../views/dict/PosPageList.vue')
  },
  {
    path: '/dictPageList',
    name: 'DictPageList',
    component: () => import('../views/dict/DictPageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/actionPageList',
    name: 'ActionPageList',
    component: () => import('../views/dict/ActionPageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/dictGroupPageList',
    name: 'DictGroupPageList',
    component: () => import('../views/dict/DictGroupPageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/dictGroupItemPageList',
    name: 'DictGroupItemPageList',
    component: () => import('../views/dict/DictGroupItemPageList.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/programmePageList',
    name: 'ProgrammePageList',
    component: () => import('../views/dict/ProgrammePageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/programmeItemPageList',
    name: 'ProgrammeItemPageList',
    component: () => import('../views/dict/ProgrammeItemPageList.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/errorPageList',
    name: 'ErrorPageList',
    component: () => import('../views/dict/ErrorPageList.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/projectPageList',
    name: 'ProjectPageList',
    component: () => import('../views/project/ProjectPageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/requirementPageList',
    name: 'RequirementPageList',
    component: () => import('../views/project/RequirementPageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/projectWbs',
    name: 'ProjectWbs',
    component: () => import('../views/project/ProjectWbs.vue')
  },
  {
    path: '/sysDictPageList',
    name: 'SysDictPageList',
    component: () => import('../views/sys/SysDictPageList.vue')
  },
  {
    path: '/sysDictItemPageList',
    name: 'SysDictItemPageList',
    component: () => import('../views/sys/SysDictItemPageList.vue')
  },
  {
    path: '/sysMenu',
    name: 'SysMenu',
    component: () => import('../views/sys/SysMenu.vue')
  },
  {
    path: '/sysRolePageList',
    name: 'SysRolePageList',
    component: () => import('../views/sys/SysRolePageList.vue')
  },
  {
    path: '/filePageList',
    name: 'FilePageList',
    component: () => import('../views/file/FilePageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/vacationPageList',
    name: 'VacationPageList',
    component: () => import('../views/note/VacationPageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/reportPageList',
    name: 'ReportPageList',
    component: () => import('../views/note/ReportPageList.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/datePageList',
    name: 'DatePageList',
    component: () => import('../views/note/DatePageList.vue')
  },
  {
    path: '/schedulerFuncPageList',
    name: 'SchedulerFuncPageList',
    component: () => import('../views/scheduler/SchedulerFuncPageList.vue')
  },
  {
    path: '/schedulerConfigPageList',
    name: 'SchedulerConfigPageList',
    component: () => import('../views/scheduler/SchedulerConfigPageList.vue')
  },
  {
    path: '/schedulerLogsPageList',
    name: 'SchedulerLogsPageList',
    component: () => import('../views/scheduler/SchedulerLogsPageList.vue')
  },

]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router
