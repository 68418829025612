import { doGet } from "@/util/request";
export default {
  name: "Menu",

  data() {
    return {
      menus: [],
      userInfo: {}
    };
  },

  methods: {
    handleSelect(key, keyPath) {// console.log(key)
      // console.log(keyPath)
      // console.log(this.$refs.menu.re);
    },

    loadUserInfo() {
      //加载用户信息
      doGet("api/system/session/session").then(data => {
        if (data && data.data && data.data.data) {
          let sessionBean = data.data.data[0];
          sessionBean.menus.forEach(element => {
            this.menus.push(element);
          }); // this.menus = sessionBean.menus;

          this.userInfo = sessionBean.userInfo;
          this.$forceUpdate();
        }
      });
    },

    logout() {
      //加载用户信息
      doGet("api/system/session/logout").then(data => {
        //清除缓存
        sessionStorage.clear(); //清除所有cookie

        var keys = document.cookie.match(/[^ =;]+(?=\=)/g);

        if (keys) {
          for (var i = keys.length; i--;) document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
        } //跳转登录


        this.$router.push({
          path: "/login"
        });
      });
    }

  },
  mounted: function () {
    this.loadUserInfo();
  }
};